<template>
    <div class="admin_sf">
        <div v-if="!noDate" class="main_list mh_list ad_list">
            <div class="mhl_item" v-for="board in 10" :key="board"  @click="$router.push('/member/1')">
                <div class="mh_listbox">
                    <ion-thumbnail class="ad_thumbnail"></ion-thumbnail>
                    <div class="ad_box">
                        <div class="ad_name">작성자이름</div>
                        <div class="ad_name fw400 pb5">dgmong@daum.net</div>
                        <div class="mhb_date fs13">2020.05.02 12:00</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    export default {
        name: "MemberListDefaultLayout",
        props: {
            boardData: {
                type: Array,
                default: () => {return []},
            },
            total: {
                type: Number,
                default: 0,
            },
            categorys: {
                type: Array,
                default: () => {return []},
            },
        },
        data() {
            return {

            }
        },
        computed: {

        },
        mounted() {

        },
        methods: {
            returnDate(date) {
                if(!date) {
                    return '';
                }
                return this.$moment(date).format('YYYY.MM.DD');
            },
            returnIndex(index) {
                return this.total - index;
            },
            goDetail(idx) {
                EventBus.$emit('boardGoDetail', idx)
            },
        },
    }
</script>

<style scoped>

</style>